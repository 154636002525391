<template>
    <div class="menu fixed flex">
        <SettingsShowHideButton @changeCalendarSettings="$emit('changeCalendarSettings', $event)" />
    </div>
</template>
<script>
export default {
    components: {
        SettingsShowHideButton:
            () => import(/* webpackChunkName: "TimeLineFloatingMenu/SettingsShowHideButton" */ './components/SettingsShowHideButton'),
    },

    data() {
        return {

        };
    },

    methods: {

    },
};
</script>
<style scoped>
    .menu {
        bottom: 10px;
        right: 10px;
        z-index: 200;
    }

    .el-range-editor {
        visibility: hidden;
    }
</style>
